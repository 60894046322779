<template>
  <section>
    <div class="titulo">
      <div class="margem container">
        <h2>Pesquisa Avançada / Relatório</h2>
      </div>
    </div>
    <div class="margem container">
      <fieldset class="grid-4 bloco2 margem">
        <div>
          <label for="dataInicial">Data Inicial</label>
          <input type="date" v-model="filtro.inicial" id="dataInicial" />
        </div>
        <div>
          <label for="dataFinal">Data Final</label>
          <input type="date" v-model="filtro.final" ref="dataFinal" id="dataFinal" />
        </div>
        <div>
          <label for="visitante">Visitante</label>
          <select v-model="filtro.visitante" id="visitante">
            <option>Sim</option>
            <option>Não</option>
          </select>
        </div>
        <div>
          <label for="pessoa">Nome Completo</label>
          <input type="text" v-model="filtro.nome" id="nome" />
        </div>
        <div>
          <label for="setor">Setor</label>
          <select v-model="setorSelecionado" id="setor">
            <option v-for="setor in listaSetores" :key="setor.id" :value="setor.id">
              {{ setor.nome }}
            </option>
          </select>
        </div>
        <div>
          <label for="local">Local</label>
          <select class="form-control form-select col-12" v-model="local">
            <option v-for="local in localLista" :key="local.id" :value="local.id">
              {{ local.local_nome }}
            </option>
          </select>
        </div>
      </fieldset>
      <div class="submit m-b"><button @click="pesquisar">Buscar</button><button @click="limparCampos" class="acao-secundaria">Limpar</button></div>
      <div class="bloco margem">
        <table class="tabela">
          <thead>
            <tr class="alinha-centro">
              <th>Nome</th>
              <th>Data/Hora Acesso</th>
              <th>Entrada/Saída</th>
              <th>Local</th>
              <th>Setor</th>
              <th>Visitante</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dadosFiltro" :key="item.id">
              <td>{{ item.nomeCompleto }}</td>
              <td>{{ formatarDataHora(item.created_at) }}</td>
              <td>
                <span v-if="item.sentido === 'e'" style="color: var(--cor-sucesso)"><i class="fa-solid fa-door-open"></i></span>
                <span v-if="item.sentido === 's'" style="color: var(--cor-erro)"><i class="fa-solid fa-person-walking-arrow-right"></i>&nbsp;</span>
              </td>
              <td>{{ item.local_nome }}</td>
              <td>{{ item.setor_nome }}</td>
              <td>{{ item.tipo_pessoa }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import { api } from "roboflex-thalamus-request-handler";
import moment from "moment-timezone";
import { sso } from "roboflex-thalamus-sso-lib";

export default {
  name: "PesquisaView",

  components: {},

  data() {
    return {
      filtro: {
        inicial: null,
        final: null,
        visitante: null,
        nome: null,
        setor: null,
        localFiltro: null,
      },
      dadosFiltro: [],
      listaSetores: [],
      setorSelecionado: null,
      localLista: [],
      local: null,
      pagina: 1,
      totalPaginas: 1,
      filtroVazio: false,
      totalResultado: null,
    };
  },

  created() {
    const usuarioLogado = sso.getUsuarioLogado();
    this.userName = usuarioLogado.nome;
    this.userId = usuarioLogado.id;
    this.local = usuarioLogado.local;
  },

  mounted() {
    this.carregarSetores();
    this.carregarLocais();
    this.dataAtual();
  },

  methods: {
    formatarDataHora(valor) {
      if (valor) {
        const dataHoraGTM3 = moment.utc(valor).tz("America/Sao_Paulo");

        return dataHoraGTM3.format("DD/MM/YYYY HH:mm:ss");
      }
      return "";
    },

    dataAtual() {
      const hoje = new Date();
      const dd = String(hoje.getDate()).padStart(2, "0");
      const mm = String(hoje.getMonth() + 1).padStart(2, "0");
      const yyyy = hoje.getFullYear();

      const dataFormatada = `${yyyy}-${mm}-${dd}`;

      this.filtro.final = dataFormatada;
    },

    /*  irParaPagina(pagina) {
             if (pagina >= 1 && pagina <= this.totalPaginas) {
                 this.pagina = pagina;
                 this.pesquisar();
             }
         }, */

    pesquisar() {
      this.filtro.visitante = this.filtro.visitante === "Sim" ? "1" : this.filtro.visitante === "Não" ? "0" : null;
      this.filtro.localFiltro = this.local;
      this.filtro.setor = this.setorSelecionado || null;

      this.dadosFiltro = [];

      console.log("filtro enviado", this.dadosFiltro);

      const dadosFiltro = (page) => {
        const params = {
          params: { ...this.filtro, page },
        };

        api
          .get("acesso/filtro", params)
          .then((response) => {
            this.dadosFiltro = this.dadosFiltro.concat(response.data);
            console.log("filtro", params);

            if (page < response.data.last_page) {
              dadosFiltro(page + 1);
            } else {
              this.totalPaginas = response.data.last_page;

              if (this.dadosFiltro.length === 0) {
                this.filtroVazio = true;
              }
              this.totalResultado = this.dadosFiltro.length;
            }
          })
          .catch((error) => {
            console.error("Erro ao buscar dados:", error);
          });
      };

      dadosFiltro(1);
    },

    carregarSetores() {
      api
        .get("setor")
        .then((response) => {
          this.listaSetores = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    carregarLocais() {
      api
        .get("local")
        .then((response) => {
          this.localLista = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    limparCampos() {
      this.filtro = {
        inicial: "",
        final: "",
        nome: "",
        setor: null,
        localFiltro: null,
        visitante: null,
      };
      this.dadosFiltro = [];
      this.setorSelecionado = null;
      this.local = null;
      this.pagina = 1;
      this.totalPaginas = 1;
      this.filtroVazio = false;
      this.totalResultado = null;
    },
  },
};
</script>
