
import { api } from "roboflex-thalamus-request-handler";

function obterTodos(){
    return new Promise((resolve, reject) => {
        return api.get('setor')
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
} 


function obterPorId(id){
    return new Promise( (resolve, reject) => {
        return api.get(`setor/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error));
    });

}

export default {
    obterTodos,
    obterPorId,
}