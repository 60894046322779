import { createRouter, createWebHashHistory } from "vue-router";
import { sso } from "roboflex-thalamus-sso-lib";
import { getPermissao } from "@/services/permissaoService";

//Acesso
import AcessoView from "../views/Acesso/AcessoView.vue";
import VisitanteView from "../views/Acesso/VisitanteView.vue";
import VisitanteCadastro from "../views/Acesso/VisitanteCadastro.vue";
import PesquisaView from "../views/Acesso/PesquisaView.vue";
//Permissão
import ColaboradorView from "@/views/Permissão/ColaboradorView";
import ControleDeColaboradorRH from "@/views/Permissão/ControleDeColaborador";
import ColaboradorRecepcaoView from "@/views/Permissão/ColaboradorRecepcaoView";

// redireciona usuario para LOGIN baseado no env
function enviarUsuarioLogin() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/login";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/login";
  }
}

// redireciona usuario para PORTAL baseado no env
function enviarUsuarioPortal() {
  const buildMode = process.env.NODE_ENV;
  if (buildMode == "production") {
    window.location.href = "https://portal.thalamus.ind.br/#/";
  } else {
    window.location.href = "http://portal-homologacao.thalamus.ind.br/#/";
  }
}

// apenas valida se esta logado
function guardMyroute(to, from, next) {
  if (sso.validarSessao()) {
    next();
  } else {
    enviarUsuarioLogin();
  }
}

// apenas valida se tem permissao
async function guardPermissao(to, from, next) {
  const permissoes = await getPermissao();
  const funcionalidadeNecessaria = to.meta.funcionalidadeId;
  if (funcionalidadeNecessaria && !permissoes.includes(funcionalidadeNecessaria)) {
    enviarUsuarioPortal();
  } else {
    next();
  }
}

// combinacao permissao + verificacao logado
function guardPermissaoRoute(to, from, next) {
  guardMyroute(to, from, (guardResultado) => {
    if (guardResultado !== undefined) {
      next(guardResultado);
    } else {
      guardPermissao(to, from, next);
    }
  });
}

const routes = [
  {
    path: "/login",
    redirect: "http://portal.thalamus.com.br/#/login",
  },

  //Acesso
  {
    path: "/",
    name: "AcessoView",
    component: AcessoView,
    beforeEnter: guardMyroute,
  },
  {
    path: "/visitante",
    name: "VisitanteView",
    component: VisitanteView,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: "/visitante/novo",
    name: "VisitanteCadastro",
    component: VisitanteCadastro,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: "/visitante/editar/:id",
    name: "EditarVisitante",
    component: VisitanteCadastro,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: "/pesquisa",
    name: "PesquisaView",
    component: PesquisaView,
    beforeEnter: guardPermissaoRoute,
  },

  //Permissão
  {
    path: "/colaboradores-rh",
    name: "ControleDeColaboradorRH",
    component: ControleDeColaboradorRH,
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: "/colaboradores/novo",
    name: "AdicionarColaborador",
    component: ColaboradorView,
    title: "Adicionar Colaborador",
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: "/colaboradores/editar/:id",
    name: "ColaboradorEditar",
    component: ColaboradorView,
    title: "Editar Colaborador",
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: "/colaboradores/excluir",
    name: "ExcluirColaborador",
    component: ColaboradorView,
    title: "Excluir Colaborador",
    beforeEnter: guardPermissaoRoute,
  },
  {
    path: "/colaboradores",
    name: "ControleDeColaborador",
    component: ColaboradorRecepcaoView,
    title: "Colaborador Recepção",
    beforeEnter: guardPermissaoRoute,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
