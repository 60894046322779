<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <div class="m-icone direita">
                    <div class="pesquisa">
                        <input
                            @input="pesquisaComFiltro"
                            v-model="filtroNome"
                            type="text"
                            placeholder="Pesquisar colaborador por nome"
                        />
                        <a class="icone-pesquisa" title="Pesquise"></a>
                    </div>
                </div>
                <h2>Colaborador</h2>
            </div>
        </div>
        <div class="margem container">
            <div class="bloco margem">
                <table class="tabela alinha-centro">
                    <thead class="alinha-centro">
                        <tr>
                            <th>Nome</th>
                            <!-- <th>Celular</th> -->
                            <!-- <th>E-mail</th> -->
                            <th>Ações</th>
                        </tr>
                    </thead>
                    <tbody class="alinha-centro">
                        <tr v-for="item in colaboradoresFiltrados" :key="item.id">
                            <td>{{ item.nomeCompleto }}</td>
                            <!-- <td>{{ item.celular }}</td> -->
                            <!-- <td>{{ item.email }}</td> -->
                            <td>
                                <div>
                                    <a
                                        title="Clique para enviar QR Code do colaborador"
                                        @click="abrirModal(item)"
                                        class="icone-email"
                                    >
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <!-- <div class="paginacao">
                <a href="#" class="ativo">1</a>
                <a href="#">2</a>
                <a href="#">3</a>
                <span>...</span>
                <a href="#">16</a>
              </div> -->
            </div>
        </div>

        <!-- MODAL ENVIO QR-CODE -->
        <div class="modal-mask" v-if="showModal" @click="fecharModalFora">
            <div class="modal-container" style="height: min-content; width: 50rem">
                <div style="display: flex; justify-content: center"></div>
                <br />
                <div class="modal-body">
                    <span
                        >Deseja enviar o QR Code para o colaborador <b>{{ selectedCollaboratorNome }}</b> para o e-mail
                        <b>{{ selectedCollaboratorEmail }}</b
                        >?</span
                    >
                    <br /><br />
                    <div class="modal-footer">
                        <button type="button" @click="confirmarEnvioQRCode">Confirmar</button>&nbsp;&nbsp;
                        <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MODAL ENVIO QR-CODE -->
    </section>
</template>

<script>
import Setores from "../../models/Setor";
import setorService from "../../services/setor-service";
import { createToaster } from "@meforma/vue-toaster";
import { api } from "roboflex-thalamus-request-handler";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    name: "ControleDeColaboradorRH",
    components: {},
    data() {
        return {
            filtroSetor: "",
            setores: [],
            setoresVisitante: [],
            pessoaIDModal: null,
            pessoaNomeModal: null,
            pessoaCPFModal: null,
            dadosVisitaModal: {},
            localId: "",
            filtroNome: "",
            result: "",
            error: "",
            colaborador: [],
            localData: [],
            page: 1,
            lastPage: null,
            currentPage: null,
            itemsPerPage: 10,
            filtro: "",
            nome: null,
            totalPages: null,
            idToDelete: null,
            idColaborador: null,
            selectedCollaboratorEmail: null,
            selectedCollaboratorNome: null,
            showModal: false,
        };
    },
    mounted() {
        this.buscaColaborador(this.page);
        this.obterTodosSetores();
        // this.nome = localStorage.getItem('userName')
    },
    computed: {
        colaboradoresFiltrados() {
            const buscaNome = this.filtroNome.toLowerCase();
            return this.colaborador.filter((item) => item.nomeCompleto.toLowerCase().includes(buscaNome));
        },
        paginatedData() {
            const itemsPerPage = "";
            const startIndex = this.currentPage * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            return this.colaborador.slice(startIndex, endIndex);
        },
    },
    methods: {
        pesquisar() {
            this.$router.push({ name: "PesquisaView" });
        },
        mostraGenero(generoAbreviado) {
            if (generoAbreviado === "f") {
                return "Feminino";
            } else if (generoAbreviado === "m") {
                return "Masculino";
            } else {
                return generoAbreviado;
            }
        },
        obterTodosSetores() {
            setorService
                .obterTodos()
                .then((response) => {
                    this.setores = response.data.map((p) => new Setores(p));
                })
                .catch((error) => {
                    console.log(error);
                    this.toast.error(" Erro ao carregar lista de setores ", { timeout: 2000 });
                });
        },
        ordenarPessoas(a, b) {
            return a.id < b.id ? -1 : a.id > b.id ? 1 : 0;
        },
        async buscaColaborador() {
            try {
                const response = await api.get(`pessoa`);
                this.colaborador = response.data;
                this.totalPages = response.data.last_page;
            } catch (error) {
                console.error("Error:", error);
            }
        },
        adicionarColaborador() {
            this.$router.push({ name: "AdicionarColaborador" });
        },
        editarColaborador(colaborador) {
            this.$router.push({ name: "ColaboradorEditar", params: { id: colaborador.id } });
            console.log("id colaborador", colaborador.id);
        },
        excluirPessoa(id) {
            this.idToDelete = id;
        },
        confirmarExclusao() {
            const id = this.idToDelete;
            const index = this.colaborador.findIndex((item) => item.id === id);
            if (index !== -1) {
                api.delete(`pessoa/${id}`)
                    .then((response) => {
                        if (response.status === 200 || response.status === 204) {
                            this.buscaColaborador(this.page);
                            toaster.show(`Colaborador excluido com sucesso`, { type: "success" });
                        } else {
                            toaster.show(`Falha ao deletar`, { type: "error" });
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            }
        },
        async pesquisaColaborador(searchTerm = "") {
            try {
                const response = await api.get(`pessoa`);
                this.colaborador = response.data || [];
                this.colaborador = this.colaborador.filter((item) =>
                    item.nomeCompleto.toLowerCase().includes(searchTerm.toLowerCase())
                );
            } catch (error) {
                console.error("Error:", error);
            }
        },
        pesquisaComFiltro() {
            this.pesquisaColaborador(this.filtroNome);
        },
        abrirModal(item) {
            this.idColaborador = item.id;
            this.selectedCollaboratorEmail = item.email;
            this.selectedCollaboratorNome = item.nomeCompleto;
            this.showModal = true;
        },
        fecharModal() {
            this.showModal = false;
        },
        async confirmarEnvioQRCode() {
            const id = this.idColaborador;
            try {
                const response = await api.post(`pessoa/enviar-qrcode/${id}`);
                if (response.cod === 1) {
                    toaster.show(`Falha ao enviar QR Code`, { type: "error" });
                } else {
                    toaster.show(`QR Code enviado`, { type: "success" });
                }
            } catch (error) {
                console.error("Error:", error);
                toaster.show(`Falha ao enviar QR Code`, { type: "error" });
            }
            this.fecharModal();
        },
        fecharModalFora(event) {
            if (event.target.classList.contains("modal-mask")) {
                this.fecharModal();
            }
        },
    },
};
</script>
