<template>
  <head>
    <title>Thalamus</title>
  </head>
  <body>
    <main id="main">
      <aside>
        <div class="titulo margem efeito">
          <div class="m-icone"><a href="#" class="icone-menu" title="Menu" onclick="document.getElementById('main').classList.toggle('esconde');return false"></a></div>
          <nav class="jm jm-inc none" id="menuIncluir">
            <div class="separador">
              <a href="#">Incluir projeto</a>
              <a href="monitoramento.html">Incluir projeto</a>
            </div>
          </nav>
        </div>
        <nav class="nav-maior">
          <router-link to="/" active-class="ativo">Acesso</router-link>
          <router-link to="/visitante/" active-class="ativo">Visitante</router-link>
          <!-- <router-link  active-class="ativo">Cadastrar Visitante</router-link> -->
          <router-link to="/pesquisa" active-class="ativo">Pesquisa / Relatório</router-link>

          <router-link to="/colaboradores" active-class="ativo">Envio de QR Code</router-link>
          <!-- <router-link to="/colaboradores-rh" active-class="ativo">Colaborador RH</router-link> -->
        </nav>
      </aside>
      <section></section>
    </main>
  </body>
</template>

<script>
export default {
  name: "NovoMenuLateral",

  methods: {
    estrutura() {
      this.$router.push("/estrutura");
    },

    associar() {
      this.$router.push("/associar");
    },
    cadastrar() {
      this.$router.push("/cadastrar/orcamento/manual");
    },
    relatorio() {
      this.$router.push("/relatorio/orcamento");
    },
    orcamento() {
      this.$router.push("/orcamentos");
    },
  },
};
</script>
