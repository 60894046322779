import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueMask from "@devindex/vue-mask";
import { api } from "roboflex-thalamus-request-handler/dist";
import store from "./store";
import "./assets/css/thalamus.css";

api.defaults.baseURL = process.env.VUE_APP_ROOT_API;

const app = createApp(App).use(store);
app.config.globalProperties.$moduleName = "Acesso";
app.use(router);
app.use(VueMask);

app.mount("#app");
