<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>{{ modoCadastro ? "Cadastrar" : "Editar" }} Colaborador</h2>
            </div>
        </div>
        <div class="grid-2 margem container">
            <fieldset class="grid-2 bloco margem">
                <div>
                    <label>Local</label>
                    <select v-model="localSelecionado" @change="alterarLocal">
                        <option v-for="local in localData" :key="local.id" :value="local.id">
                            {{ local.local_nome }}
                        </option>
                    </select>
                </div>

                <div>
                    <label for="nomeCompleto">Nome&nbsp;<i style="color: var(--cor-erro)">*</i></label>
                    <input type="text" id="nomeCompleto" v-model="nomeCompleto" />
                </div>
                <div>
                    <label for="cpf">CPF</label>
                    <input type="text" id="CPF" v-model="CPF" />
                </div>
                <div>
                    <label for="cpf">Data de nascimento</label>
                    <input type="date" id="dtNasc" v-model="dtNasc" />
                </div>
                <div>
                    <label for="email">E-mail</label>
                    <input type="text" id="email" v-model="email" />
                </div>

                <div>
                    <label for="celular">Celular</label>
                    <input type="text" id="celular" v-model="celular" />
                </div>

                <div>
                    <label for="setor">Setor&nbsp;<i style="color: var(--cor-erro)">*</i></label>
                    <select id="id_setor" v-model="id_setor">
                        <option v-for="setor in setores" :key="setor.id" :value="setor.id">{{ setor.nome }}</option>
                    </select>
                </div>
                <div class="alinha-v">
                    <input type="checkbox" id="checkUsuario" value="usuarioGerar" v-model="gerarUsuarioCheck" />
                    <label for="checkUsuario">Gerar Usuario ? </label>
                </div>

                <div class="submit m-b">
                    <button @click="salvarPessoa">Salvar</button>
                    <button @click="cancelar" class="acao-secundaria">Cancelar</button>
                </div>
            </fieldset>

            <div class="bloco margem alinha-centro">
                <label>Envio de imagem do colaborador&nbsp;<i style="color: var(--cor-erro)">*</i></label>

                <input type="file" id="foto" @change="handleFileUpload" accept="image/png, image/jpeg" />
                <br /><br />

                <img
                    :src="fotoPessoa"
                    alt="foto colaborador"
                    v-if="fotoPessoa"
                    style="border-radius: 30px; max-width: 100%; max-height: 400px"
                />
                <img
                    src="../../../public/img/user-avatar.png"
                    alt="foto colaborador"
                    v-if="!fotoPessoa"
                    style="border-radius: 30px; max-width: 100%; max-height: 400px"
                />
            </div>

            <div class="bloco margem">
                <div>
                    <button @click="iniciaLeitor" title="Vincular cartão">
                        Vincular cartão&nbsp;<i class="fa-regular fa-address-card"></i>
                    </button>
                    <span v-if="qrCodeCartao">QR Code lido: {{ qrCodeCartao }}</span>
                </div>
            </div>
        </div>

        <!-- MODAL QRCODE -->
        <div class="modal-mask" v-if="showModalQRCode" @click="fecharModalForaQRCode">
            <div class="modal-container" style="height: min-content; width: 50rem">
                <div style="display: flex; justify-content: center"></div>
                <br />
                <div class="modal-body">
                    <h1 class="modal-title fs-5">Fazendo leitura do QR Code</h1>
                    <div class="camera">
                        <qrcode-stream v-if="cameraAberta && !qrcodeWebcam" @decode="onDecode" @init="onInit" />
                    </div>
                    <div>
                        <span v-if="mostraAlerta" style="color: var(--cor-sucesso); font-weight: 600"
                            >QR Code lido</span
                        >
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="fecharModalQRCode" class="btn btn-primary">Ok</button>
                </div>
            </div>
        </div>
        <!-- END MODAL QRCODE -->

        <!-- MODAL EDIÇÃO-->
        <div v-if="modalEdicao" class="modal-mask" @click="fecharModalFora">
            <div
                class="modal-container"
                id="staticBackdrop"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                style="max-width: 20%"
            >
                <div class="modal-content">
                    <div class="modal-header">
                        <h1 class="modal-title fs-5" id="staticBackdropLabel">Deseja ativar o Modo Edição ?</h1>
                        <button
                            type="button"
                            class="btn-close"
                            @click="fecharModalEdicao()"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        ></button>
                    </div>
                    <br />
                    <div class="modal-body">
                        <div class="row">
                            <div class="col d-flex justify-content">
                                <button class="button-cadastro" @click="confirmarEdicao(true)">Sim</button>&nbsp;&nbsp;
                                <button class="button-cadastro" @click="confirmarEdicao(false)">Não</button>
                                <br /><br />
                            </div>
                            <br /><br />
                        </div>
                        <br />
                        <span @click="downloadPlanilha" style="text-decoration: underline; cursor: pointer"
                            >Download Planilha&nbsp;<i class="fa-solid fa-download"></i
                        ></span>

                        <br />
                    </div>
                </div>
            </div>
        </div>
        <!-- END MODAL EDIÇÃO -->
    </section>
</template>

<script>
import Pessoa from "../../models/Pessoa.js";
import Usuario from "@/models/Usuario";
import usuarioService from "@/services/usuario-service";
import WebSocketService from "../../services/websocketservice";
import { createToaster } from "@meforma/vue-toaster";
import { QrcodeStream } from "vue-qrcode-reader";
import { api } from "roboflex-thalamus-request-handler";
import { sso } from "roboflex-thalamus-sso-lib";

const toaster = createToaster({
    position: "top-right",
    duration: 6000,
});

export default {
    name: "ColaboradorComponent",
    components: {
        QrcodeStream,
    },
    data() {
        return {
            pessoa: new Pessoa({ id_setor: null }),
            usuario: new Usuario(),
            modoCadastro: true,
            nomeCompleto: "",
            dtNasc: "",
            sexo: "",
            CPF: "",
            email: "",
            celular: "",
            id_setor: "",
            pessoa_img: "",
            filtroSetor: "",
            setores: [],
            fotoPessoa: "",
            mostraFoto: false,
            wsService: new WebSocketService(),
            localSelecionado: 1,
            localData: [],
            mostraCapturado: false,
            gerarUsuarioCheck: "",
            emailCriar: "",
            loading: false,
            qrCodeCartao: "",
            mostraAlerta: false,
            cameraAberta: false,
            qrcodeWebcam: null,
            showModalQRCode: false,
            result: "",
            error: "",
        };
    },
    created() {
        let id = this.$route.params.id;
        if (id) {
            this.modoCadastro = false;
            this.obterPessoaPorId(id);
        } else {
            this.modoCadastro = true;
        }
        this.gerarUsuarioCheck = this.modoCadastro;
    },
    beforeUnmount() {
        this.wsService.removeListener(this.handleMessage);
        this.wsService.close();
    },
    mounted() {
        this.getAllSetor();
        this.buscaLocal();

        const usuarioLogado = sso.getUsuarioLogado();
        this.userName = usuarioLogado.nome;
        this.userId = usuarioLogado.id;
        this.local = usuarioLogado.local;
    },
    methods: {
        buscaLocal() {
            api.get("local")
                .then((response) => {
                    const localLista = response.data;
                    this.localData = localLista;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async alterarLocal() {
            if (this.localSelecionado !== null) {
                try {
                    console.log("calling from alterarLocal()");
                    const response = await api.get(`local/${this.localSelecionado}/acessos-hoje`);
                    const responseData = response.data;
                    this.acessos = responseData.data || [];
                    // localStorage.setItem('localSelecionado', JSON.stringify(this.localSelecionado));
                } catch (error) {
                    console.error("Error ao buscar locais", error);
                }
            }
        },
        handleFileUpload(event) {
            this.mostraFoto = true;
            const file = event.target.files[0];
            if (file) {
                this.fotoPessoa = URL.createObjectURL(file);
                this.pessoa_img = file;
            } else {
                this.fotoPessoa = null;
                this.pessoa_img = null;
            }
        },
        getAllSetor() {
            api.get("setor")
                .then((response) => {
                    this.setores = response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        obterPessoaPorId(id) {
            api.get(`pessoa/${id}`)
                .then((response) => {
                    const pessoaData = response.data;
                    this.imagePath = response.data.path_image;
                    this.nomeCompleto = pessoaData.nomeCompleto;
                    this.dtNasc = pessoaData.dtNasc;
                    this.CPF = pessoaData.CPF;
                    this.sexo = pessoaData.sexo;
                    this.email = pessoaData.email;
                    this.celular = pessoaData.celular;
                    this.id_setor = pessoaData.id_setor;
                    this.mostraFotoPessoa();
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        mostraFotoPessoa() {
            try {
                if (this.imagePath) {
                    const urlfoto = process.env.VUE_APP_ROOT_STORAGE;
                    this.fotoPessoa = urlfoto + this.imagePath;
                    this.mostraFoto = true;
                } else {
                    console.error("Cadastro sem foto");
                }
            } catch (error) {
                console.error(error);
            }
        },
        adicionarSePresente(formData, chave, valor) {
            if (valor !== undefined && valor !== null) {
                formData.append(chave, valor);
            }
        },
        cadastrarColaborador() {
            const formData = new FormData();
            this.adicionarSePresente(formData, "nomeCompleto", this.nomeCompleto);
            this.adicionarSePresente(formData, "id_setor", this.id_setor);
            this.adicionarSePresente(formData, "dtNasc", this.dtNasc);
            this.adicionarSePresente(formData, "sexo", this.sexo);
            this.adicionarSePresente(formData, "CPF", this.CPF);
            this.adicionarSePresente(formData, "email", this.email);
            this.adicionarSePresente(formData, "celular", this.celular);
            this.adicionarSePresente(formData, "pessoa_img", this.pessoa_img);
            if (this.qrCodeCartao) {
                formData.append("qrcode", this.qrCodeCartao);
            }
            api.post("pessoa", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((response) => {
                    console.log("cadastro pessoa ", response);
                    const responseData = response.data;
                    if (responseData.cod === 1) {
                        this.qrCodeCartao = "";
                    } else {
                        this.$router.push({ name: "ControleDeColaborador" });
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        cadastrarPessoa() {
            this.loading = true;
            this.usuario.email = this.email;
            this.usuario.name = this.nomeCompleto;
            this.emailCriar = this.usuario.email;
            const formData = new FormData();
            if (!this.nomeCompleto || !this.sexo || !this.id_setor) {
                this.loading = false;
                toaster.show(`Por favor, preencha os campos obrigatórios`, { type: "error" });
            } else {
                this.loading = true;
                if (this.gerarUsuarioCheck) {
                    this.adicionarSePresente(formData, "nomeCompleto", this.nomeCompleto);
                    this.adicionarSePresente(formData, "id_setor", this.id_setor);
                    this.adicionarSePresente(formData, "dtNasc", this.dtNasc);
                    this.adicionarSePresente(formData, "sexo", this.sexo);
                    this.adicionarSePresente(formData, "CPF", this.CPF);
                    this.adicionarSePresente(formData, "email", this.email);
                    this.adicionarSePresente(formData, "celular", this.celular);
                    this.adicionarSePresente(formData, "pessoa_img", this.pessoa_img);
                    this.loading = false;
                    if (this.qrCodeCartao) {
                        formData.append("qrcode", this.qrCodeCartao);
                    }
                    api.post("pessoa", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                        .then((response) => {
                            const responseData = response.data;
                            if (responseData.cod === 1) {
                                this.qrCodeCartao = "";
                            }
                            usuarioService
                                .cadastrar(this.usuario)
                                .then(() => {
                                    this.loading = true;
                                    this.usuario = new Usuario();
                                    api.post("enviar-codigo", {
                                        email: this.emailCriar,
                                    }).then((res) => {
                                        toaster.show(
                                            `Colaborador e usuário criado com sucesso! Email enviado para ` +
                                                this.email,
                                            { type: "success" }
                                        );
                                        this.$router.push({ name: "ControleDeColaborador" });
                                        this.loading = false;
                                        console.log(res);
                                    });
                                })
                                .catch((error) => {
                                    this.loading = false;
                                    console.error(error);
                                });
                        })
                        .catch((error) => {
                            this.loading = false;
                            console.error(error);
                        });
                } else {
                    this.loading = true;
                    this.adicionarSePresente(formData, "nomeCompleto", this.nomeCompleto);
                    this.adicionarSePresente(formData, "id_setor", this.id_setor);
                    this.adicionarSePresente(formData, "dtNasc", this.dtNasc);
                    this.adicionarSePresente(formData, "sexo", this.sexo);
                    this.adicionarSePresente(formData, "CPF", this.CPF);
                    this.adicionarSePresente(formData, "email", this.email);
                    this.adicionarSePresente(formData, "celular", this.celular);
                    this.adicionarSePresente(formData, "pessoa_img", this.pessoa_img);
                    if (this.qrCodeCartao) {
                        formData.append("qrcode", this.qrCodeCartao);
                    }
                    api.post("pessoa", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                        .then((response) => {
                            this.loading = false;
                            const responseData = response.data;
                            if (responseData.cod === 1) {
                                this.qrCodeCartao = "";
                            } else {
                                this.loading = false;
                                toaster.show(`Colaborador cadastrado com sucesso!`, { type: "success" });
                                this.$router.push({ name: "ControleDeColaborador" });
                            }
                        })
                        .catch((error) => {
                            this.loading = false;
                            console.error(error);
                        });
                }
            }
        },
        atualizarPessoa() {
            this.loading = true;
            const id = this.$route.params.id;
            const formData = new FormData();
            this.adicionarSePresente(formData, "nomeCompleto", this.nomeCompleto);
            this.adicionarSePresente(formData, "id_setor", this.id_setor);
            this.adicionarSePresente(formData, "dtNasc", this.dtNasc);
            this.adicionarSePresente(formData, "sexo", this.sexo);
            this.adicionarSePresente(formData, "CPF", this.CPF);
            this.adicionarSePresente(formData, "email", this.email);
            this.adicionarSePresente(formData, "celular", this.celular);
            this.adicionarSePresente(formData, "pessoa_img", this.pessoa_img);
            if (this.qrCodeCartao) {
                formData.append("qrcode", this.qrCodeCartao);
            }
            api.post(`pessoa/${id}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
                .then((response) => {
                    const responseData = response.data;
                    if (responseData.cod === 1) {
                        this.loading = false;
                        toaster.show(`Qr Code já existente. Por favor, escolha um QR code diferente`, {
                            type: "error",
                        });
                    } else {
                        this.loading = false;
                        this.$router.push({ name: "ControleDeColaborador" });
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error("Erro ao atualizar colaborador:", error);
                });
            toaster.show(`Colaborador atualizado com sucesso!`, { type: "success" });
        },
        salvarPessoa() {
            if (this.modoCadastro) {
                this.cadastrarPessoa();
            } else {
                this.atualizarPessoa();
            }
        },
        cancelar() {
            this.pessoa = new Pessoa();
            this.$router.push({ name: "ControleDeColaboradorRH" });
        },
        iniciaLeitor() {
            this.wsService.addListener(this.handleMessage);
            const chamaLeitorQrcode = JSON.stringify({
                mensagem: "iniciar_leitor",
                local_id: this.local,
            });
            this.wsService.send(chamaLeitorQrcode);
            console.log("chamando websocket...");
            console.log("chamando", chamaLeitorQrcode);
            const timeout = 2000;
            const timeoutId = setTimeout(() => {
                console.log("tablet não respondeu, chamando webcam...");
                this.wsService.removeListener(this.handleMessage);
                this.iniciarLeituraWebcam();
            }, timeout);
            this.wsService.addListener(() => {
                clearTimeout(timeoutId);
                this.wsService.removeListener(this.handleMessage);
            });
            this.qrCodeCartao = "";
            this.mostraAlerta = false;
            this.showModalQRCode = true;
        },
        handleMessage(event) {
            try {
                if (event && event.data) {
                    const message = event.data;
                    const messageData = JSON.parse(message);
                    if (messageData.mensagem === "qr_capturado") {
                        this.qrCodeCartao = messageData.qrcode;
                        console.log("leu no tablet:", this.qrCodeCartao);
                        this.mostraAlerta = true;
                        toaster.show(`Qr code capturado`, { type: "success" });
                        this.fechaModal();
                        return;
                    }
                } else {
                    console.log("Sem resposta do websocket");
                }
            } catch (error) {
                console.error(error);
            }
        },
        iniciarLeituraWebcam() {
            this.cameraAberta = true;
        },
        onDecode(result) {
            this.qrCodeCartao = "";
            this.mostraAlerta = false;
            console.log("tem qr code?", this.qrCodeCartao);
            this.qrCodeCartao = result;
            console.log("lido na webcam:", this.qrCodeCartao);
            this.cameraAberta = false;
            this.showModalQRCode = false;
            toaster.show(`Qr code capturado`, { type: "success" });
        },
        async onInit(promise) {
            try {
                await promise;
                console.log("oninit ", promise);
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    this.error = "ERROR: you need to grant camera access permisson";
                    console.log(this.error);
                    toaster.show(`Sem permissão para acessar a webcam`, { type: "error" });
                    this.showModalQRCode = false;
                } else if (error.name === "NotFoundError") {
                    this.error = "ERROR: no camera on this device";
                    console.log(this.error);
                    toaster.show(`Webcam não encontrada`, { type: "error" });
                    this.showModalQRCode = false;
                } else if (error.name === "NotSupportedError") {
                    this.error = "ERROR: secure context required (HTTPS, localhost)";
                    console.log(this.error);
                    this.showModalQRCode = false;
                } else if (error.name === "NotReadableError") {
                    this.error = "ERROR: is the camera already in use?";
                    console.log(this.error);
                    toaster.show(`Webcam está em uso`, { type: "error" });
                    this.showModalQRCode = false;
                } else if (error.name === "OverconstrainedError") {
                    this.error = "ERROR: installed cameras are not suitable";
                    console.log(this.error);
                    this.showModalQRCode = false;
                } else if (error.name === "StreamApiNotSupportedError") {
                    this.error = "ERROR: Stream API is not supported in this browser";
                    console.log(this.error);
                    this.showModalQRCode = false;
                }
            }
        },
        fecharModalQRCode() {
            this.showModalQRCode = false;
        },
        fecharModalForaQRCode(event) {
            if (event.target.classList.contains("modal-mask")) {
                this.showModalQRCode = false;
            }
        },
    },
};
</script>
